import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import {
  Container,
  Section,
  Seo,
} from '@components'
import { LandingPageLayout } from '../../../components/site/layout/landing-page-layout'
import { OfferForm } from '../../../components/site/offer'

const offer = 'secrets_whitepaper'
const imgDir = '../../../components/site/images'

const Headline = () => {
  return (
    <Section>
      <Container className="mb-8 container-lg">
        <h1 className="mt-12 mb-4 text-3xl text-center">
          Best Practices For Secure Access of Thirds Party APIs From Mobile Apps
        </h1>
      </Container>
    </Section>
  )
}

const Pitch = () => {
  return (
    <div>
      <div className="mx-16 lg:mx-32">
        <StaticImage
          src={`${imgDir}/api-key-practices.jpg`}
          alt="API Keys Whitepaper Image"
          placeholder="blurred"
        />
      </div>
    </div>
  )
}

const Action = () => {
  return (
    <div>
      <h3 className="mt-4 mb-2 text-2xl text-center text-gray-700 lg:mt-0">
        Register Here to Download your Copy
      </h3>
      <OfferForm className="pt-0 pl-4 pr-0" offer={offer} />
    </div>
  )
}

const Details = () => {
  return (
    <Section>
      <Container className="mb-8 container-md">
        <div className="pt-0 pl-8 -mt-4">
          <p>
            Mobile apps increasingly depend on third-party API access, employing
            them for many reasons, including payment, location, social media and
            other services. Access is validated via API keys but these keys are
            being stolen, either from the mobile app code itself or from cloud
            repositories. If APIs are abused using keys you have not protected
            you could be exposed to financial losses, fines, and reputational
            damage due to:
          </p>
          <ul>
            <li>
              Hackers using your API keys to access a third-party service you
              pay for.
            </li>
            <li>
              Data breaches executed via third-party API traffic interception.
            </li>
            <li>
              DDoS attacks on APIs using your keys, causing services to stop
              responding either because service allocation limits are used up,
              or service rate limits are triggered.
            </li>
          </ul>
          <p>
            Michael Sampson, senior analyst at Osterman Research says “Our
            research shows that mobile apps depend on average on more than 30
            third-party APIs, and that half of the mobile developers we surveyed
            are still storing API keys in the app code. These two things
            together constitute a massive attack surface for bad actors to
            exploit. And third-party API threats against mobile apps aren’t as
            well understood by companies as they should be.“
          </p>

          <p>
            This new whitepaper from Approov presents an in-depth view of the
            threats as well as the pros and cons of different approaches to
            securing third-party API access.
          </p>

          <p>
            You will learn how to eliminate the storage of secrets in the mobile
            app code completely, removing any risk of extraction through code
            analysis, as well as the risk of exposure through accidental source
            code repository leaks.
          </p>

          <h3>Contents</h3>

          <p>
            Introduction
            <br />
            The Problem with API Keys
            <br />
            Third-Party API Access from Mobile
            <br />
            How a Man-in-the Middle Attack Works
            <br />
            The Risks of Stolen API Keys
            <br />
            Existing Protection Techniques
            <br />
            First-Party API Protection with Approov
            <br />
            Approov Architecture
            <br />
            App and Device Environment Checks
            <br />
            But What About 3rd Party APIs
            <br />
            Managed Trust Roots
            <br />
            Quickstart Integrations
            <br />
            App Instance Secure Strings
            <br />
            Summary
            <br />
          </p>
        </div>
      </Container>
    </Section>
  )
}

const LandingPage = ({ location }) => {
  return (
    <LandingPageLayout pathname={location.pathname}>

      <Headline />

      <Section>
        <Container className="mb-8 container-lg">
          <div className="flex flex-col justify-center lg:flex-row lg:content-center lg:items-center">
            <div className="flex items-center justify-center lg:w-7/12">
              <Pitch />
            </div>

            <div className="lg:pl-24 lg:pr-0 lg:w-5/12">
              <Action />
            </div>
          </div>
        </Container>
      </Section>

      <Details />
    </LandingPageLayout>
  )
}

export default LandingPage
